<template>
	<div>DATA</div>
</template>

<script lang="ts">

export default {
	name: 'dashboardView',
	components: {},
	computed: {},
	data() {
		return {}
	},
	mounted() {
	},
	methods: {

	},
}
</script>